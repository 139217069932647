import { GetTranslationsResponseAll, TranslationsFilter } from '../components/translations/types';
import axios from '../utils/axios';

export const getAllTranslations = async (filter: TranslationsFilter) => {
    try {
        const response = await axios.post<GetTranslationsResponseAll>('translations', filter);
        return response.data;
    } catch (err) {
        console.log(err);
        return { count: 0, translations: [] };
    }
};
