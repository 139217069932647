import { Form, Input, message, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import axios from '../../../utils/axios';
import { GetTranslationsResponse } from '../types';

type Props = {
    editingRecord: GetTranslationsResponse | undefined;
    setEditingRecord: (record: GetTranslationsResponse | undefined) => void;
    onSave: () => void;
};

const { TextArea } = Input;

export function TranslationsEditModal({ editingRecord, setEditingRecord, onSave }: Props) {
    const isOpen = editingRecord !== undefined;
    const [form] = useForm();

    useEffect(() => {
        if (!editingRecord) return;

        form.setFieldsValue({
            en: editingRecord?.translations.find((t) => t.language === 'en')?.text,
            ee: editingRecord?.translations.find((t) => t.language === 'ee')?.text,
            ru: editingRecord?.translations.find((t) => t.language === 'ru')?.text,
        });
    }, [editingRecord]);

    const save = async () => {
        if (!editingRecord) return;
        const changes: { id: string | undefined; text: string; language: string }[] = [];
        ['en', 'ee', 'ru'].forEach((lang: string) => {
            const translation = editingRecord.translations?.find((t) => t.language === lang);
            changes.push({ id: translation?.id, text: form.getFieldValue(lang), language: lang });
        });
        try {
            await axios.post('/translations/update', { key: editingRecord.key, changes });
            onSave();
            setEditingRecord(undefined);
            message.success('Translations updated successfully');
        } catch (err) {
            console.log(err);
            message.warning('Failed to update translations');
        }
    };

    return (
        <Modal open={isOpen} onCancel={() => setEditingRecord(undefined)} title="Update translations" onOk={save}>
            <Form form={form}>
                <Form.Item name="en" label="English">
                    <TextArea />
                </Form.Item>
                <Form.Item name="ru" label="Russian">
                    <TextArea />
                </Form.Item>
                <Form.Item name="ee" label="Estonian">
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
}
