import React from 'react';
import { ContainerOutlined, EuroCircleOutlined, LogoutOutlined, TranslationOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { useState } from 'react';
import Wrapper from './styles';
import { removeToken } from '../../utils/token';
import { store } from '../../store/store';
import { setIsAuthenticated } from '../../store/slices/authSlice';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';

interface Props {
    children?: JSX.Element;
}

type MenuItem = {
    label: React.ReactNode;
    key: React.Key;
    icon?: React.ReactNode;
    children?: MenuItem[];
    onClick?: () => void;
    isActive: boolean;
};

export function LayoutBasic({ children }: Props) {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    function getItem({
        label,
        key,
        icon,
        children,
        onClick,
        matchRoute,
    }: {
        label: React.ReactNode;
        key: React.Key;
        icon?: React.ReactNode;
        children?: MenuItem[];
        onClick?: () => void;
        matchRoute: string;
    }) {
        const isActive = matchPath(matchRoute, location.pathname);
        return {
            key,
            icon,
            children,
            label,
            onClick,
            isActive: isActive !== null,
        } as MenuItem;
    }

    const items: MenuItem[] = [
        getItem({ label: 'Users', key: 'users', icon: <UserOutlined />, onClick: () => navigate('/users'), matchRoute: '/users/*' }),
        getItem({
            label: 'Withdraw Requests',
            key: 'withdraw-requests',
            icon: <EuroCircleOutlined />,
            onClick: () => navigate('/withdraw-requests'),
            matchRoute: '/withdraw-requests/*',
        }),
        getItem({
            label: 'Feedback',
            key: 'feedback',
            icon: <ContainerOutlined />,
            onClick: () => navigate('/feedback'),
            matchRoute: '/feedback/*',
        }),
        getItem({
            label: 'Translations',
            key: 'translations',
            icon: <TranslationOutlined />,
            onClick: () => navigate('/translations'),
            matchRoute: '/translaitons/',
        }),
        getItem({ label: 'Logout', key: 'logout', icon: <LogoutOutlined />, onClick: logout, matchRoute: '/' }),
    ];

    function logout() {
        removeToken();
        store.dispatch(setIsAuthenticated(false));
        navigate('/');
    }
    return (
        <Wrapper>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <Menu
                        theme="dark"
                        defaultSelectedKeys={items.filter((item) => item.isActive).map((item) => item.key.toString())}
                        mode="inline"
                        items={items.map((item) => {
                            const { isActive, ...props } = item;
                            return props;
                        })}
                    />
                </Sider>
                <Layout style={{ padding: '1rem 1rem' }}>{children}</Layout>
            </Layout>
        </Wrapper>
    );
}
