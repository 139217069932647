import { Button, Checkbox, Form, GetProp, Input, Space, Table, TableProps, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { getAllTranslations } from '../../../api/translations';
import { GetTranslationsResponse, Translation, TranslationsFilter } from '../types';
import { TranslationsEditModal } from './TranslationsEditModal';

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface TableParams {
    pagination: TablePaginationConfig;
}

export function TranslationsList() {
    const [translations, setTranslations] = useState<GetTranslationsResponse[]>([]);
    const [editingRecord, setEditingRecord] = useState<GetTranslationsResponse>();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 20,
        },
    });
    const [filterForm] = useForm();

    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
        },
        {
            title: 'English',
            dataIndex: 'translations',
            render: (translations: Translation[]) => translations.find((t) => t.language === 'en')?.text,
        },
        {
            title: 'Russian',
            dataIndex: 'translations',
            render: (translations: Translation[]) => translations.find((t) => t.language === 'ru')?.text,
        },
        {
            title: 'Estonian',
            dataIndex: 'translations',
            render: (translations: Translation[]) => translations.find((t) => t.language === 'ee')?.text,
        },
        {
            title: 'Actions',
            render: (record: GetTranslationsResponse) => {
                return <Typography.Link onClick={() => edit(record)}>Edit</Typography.Link>;
            },
        },
    ];

    useEffect(() => {
        loadTranslations();
    }, [tableParams.pagination.pageSize, tableParams.pagination.current]);

    const loadTranslations = async (filters: TranslationsFilter = { search: undefined, missingTranslations: false }) => {
        const { count, translations } = await getAllTranslations({
            ...filters,
            pageSize: tableParams.pagination.pageSize,
            current: tableParams.pagination.current,
        });
        setTranslations(translations);
        setTableParams((prev) => {
            return {
                ...prev,
                pagination: {
                    current: tableParams.pagination.current,
                    pageSize: tableParams.pagination.pageSize,
                    total: count,
                },
            };
        });
        window.scrollTo(0, 0);
    };

    const edit = (record: GetTranslationsResponse) => {
        setEditingRecord(record);
    };

    const handleTableChange: TableProps<GetTranslationsResponse>['onChange'] = async (pagination) => {
        setTableParams((prev) => ({
            ...prev,
            pagination,
        }));
    };

    const filterTranslations = async (data: any) => {
        await loadTranslations({ search: data.search, missingTranslations: data.missingTranslations });
    };

    const resetFilters = async () => {
        filterForm.setFieldsValue({ search: undefined, missingTranslations: false });
        await loadTranslations();
    };

    return (
        <Space direction="vertical">
            <Form layout="inline" form={filterForm} onFinish={filterTranslations}>
                <Form.Item name="search">
                    <Input placeholder="Search..." style={{ minWidth: '400px' }} />
                </Form.Item>
                <Form.Item name="missingTranslations" valuePropName="checked">
                    <Checkbox>Missing translations</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        Filter
                    </Button>
                </Form.Item>
                <Button onClick={resetFilters}>Clear</Button>
            </Form>
            <Table
                columns={columns}
                dataSource={translations}
                rowKey="key"
                pagination={tableParams.pagination}
                onChange={handleTableChange}
            />
            <TranslationsEditModal editingRecord={editingRecord} setEditingRecord={setEditingRecord} onSave={loadTranslations} />
        </Space>
    );
}
